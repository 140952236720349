/** @jsx jsx */
import { jsx } from "theme-ui";
import Container from "@components/Container";
import ResourceCard from "@components/ResourceCard";
import PlainCard from "@components/PlainCard";
import { rem } from "@src/theme";
import { Link } from "gatsby";
import TypeTag from "@components/TypeTag";
import { kebabize, formatCraftType } from "@src/helpers/utils";
import ExternalLink from "@components/icons/link";
import Download from "@components/icons/download";
import arrowIllustration from "@src/assets/images/arrow.svg";
import Layout from "@components/Layout";
import BackBtn from "@components/BackBtn";
import SEO from "@components/seo";

interface ComponentProps {
  pageType: string;
  entry: any;
  location: any;
}

export default ({ pageType = "resource", entry, location }: ComponentProps) => {
  const {
    title,
    description,
    type,
    links,
    attachments,
    tags,
    authors,
    organisations,
    related,
    video,
    img,
  } = entry;
  const hasResources =
    (links && links.length) || (attachments && attachments.length)
      ? true
      : false;

  const resourcesHeading = pageType !== "resource" ? "Links" : "Resources";
  const showAuthors = pageType === "resource";
  const showOrganisations = pageType === "organisation";
  const relatedContentHeading =
    pageType === "resource" ? "Related research" : `Research by ${title}`;
  const showTags = pageType === "resource";

  return (
    <Layout>
      <SEO
        metadata={{
          metaTitle: title,
          metaDescription: description,
        }}
        location={location}
      />
      <div>
        <div
          sx={{
            bg: "csiLightGrey",
            pt: [rem(25), null, rem(70)],
            pb: [rem(35), null, rem(50)],
          }}
        >
          <Container>
            <div
              sx={{
                backgroundImage: `url(${arrowIllustration})`,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "right",
                backgroundPositionY: "top",
              }}
            >
              <BackBtn />
            </div>
          </Container>
        </div>

        <div
          sx={{
            pt: [rem(40), null, rem(80)],
          }}
        >
          <Container>
            <div
              sx={{
                display: "flex",
                flexDirection: ["column", null, "row"],
                justifyContent: "space-between",
              }}
            >
              <div
                sx={{
                  width: ["100%", null, rem(545)],
                }}
              >
                <TypeTag type={formatCraftType(type).toLowerCase()} />
                <h1
                  sx={{
                    mt: rem(10),
                    fontSize: [rem(30), null, rem(45)],
                  }}
                >
                  {title}
                </h1>

                {img && (
                  <div>
                    <img
                      src={img.src}
                      alt={img.alt}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </div>
                )}

                {video && (
                  <div>
                    <iframe
                      sx={{
                        width: "100%",
                        height: [rem(200), null, rem(300)],
                        mb: [rem(30), null, rem(40)],
                      }}
                      src={video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
                {description && (
                  <p
                    sx={{
                      my: 0,
                      fontSize: [rem(16), null, rem(18)],
                      lineHeight: 1.35,
                      whiteSpace: "pre-line",
                    }}
                  >
                    {description}{" "}
                  </p>
                )}
                {showTags && (
                  <section>
                    <h2
                      sx={{
                        mt: [rem(40), null, rem(70)],
                        fontSize: [rem(18), null, rem(24)],
                        // pb: rem(20),
                      }}
                    >
                      Related tags
                    </h2>
                    {tags && tags.length ? (
                      <ul
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          mt: [rem(20), null, rem(30)],
                          mb: rem(20),
                        }}
                      >
                        {tags.map(({ title, groupHandle, slug }) => (
                          <PlainCard
                            link={`/explore/${kebabize(groupHandle)}/${slug}`}
                            label={title}
                            size="xs"
                            key={slug}
                          />
                        ))}
                      </ul>
                    ) : null}
                  </section>
                )}
              </div>

              <div
                sx={{
                  width: ["100%", null, rem(308)],
                  pt: rem(40),
                }}
              >
                {hasResources ? (
                  <section
                    sx={{
                      pb: rem(15),
                    }}
                  >
                    <h2
                      sx={{
                        variant: ["typography.headingXL"],
                        mt: 0,
                      }}
                    >
                      {resourcesHeading}
                    </h2>

                    <ul
                      sx={{
                        li: {
                          fontSize: [rem(14), rem(16)],
                          fontWeight: 600,
                          mb: rem(20),
                          mt: [rem(10), null, rem(20)],
                          lineHeight: 1.4,
                          wordBreak: "break-word",
                        },
                        span: {
                          display: "inline-block",
                          marginRight: rem(8),
                          transform: "translateY(2px)",
                        },
                      }}
                    >
                      {attachments
                        ? attachments.map(({ filename, url }) => (
                            <li key={url}>
                              <a
                                className="link-hover"
                                href={url}
                                target="_blank"
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <span>
                                  <Download />
                                </span>
                                {filename}
                              </a>
                            </li>
                          ))
                        : null}
                      {links
                        ? links.map(({ linkURL, label }) => (
                            <li key={label}>
                              <a
                                className="link-hover"
                                href={linkURL}
                                target="_blank"
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <span>
                                  <ExternalLink />
                                </span>
                                {label || linkURL}
                              </a>
                            </li>
                          ))
                        : null}
                    </ul>
                  </section>
                ) : null}

                {showAuthors && authors && authors.length ? (
                  <section>
                    <h2
                      sx={{
                        variant: ["typography.headingXL"],
                        mb: rem(10),
                        mt: rem(10),
                      }}
                    >
                      Authors
                    </h2>
                    <ul
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        span: {
                          mr: rem(2),
                          mt: [rem(6), null, rem(6)],
                          variant: "typgoraphy.paragraphXS",
                          a: {
                            textDecoration: "underline",
                          },
                        },
                      }}
                    >
                      {authors.map(({ title, uri }, index) => (
                        <span key={uri}>
                          <Link to={`/${uri}`} className="link-hover-invert">
                            {title}
                          </Link>
                          {index !== authors.length - 1 && ","}
                        </span>
                      ))}
                    </ul>
                  </section>
                ) : null}

                {showOrganisations && organisations && organisations.length ? (
                  <section>
                    <h2
                      sx={{
                        variant: ["typography.headingXL"],
                        mb: rem(5),
                        mt: rem(50),
                      }}
                    >
                      Organisations
                    </h2>

                    <ul
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        span: {
                          mr: rem(2),
                          mt: [rem(6), null, rem(6)],
                          variant: "typgoraphy.paragraphXS",
                          a: {
                            textDecoration: "underline",
                          },
                        },
                      }}
                    >
                      {organisations.map(({ title, uri }, index) => (
                        <span key={uri}>
                          <Link to={`/${uri}`} className="link-hover-invert">
                            {title}
                          </Link>
                          {index !== organisations.length - 1 && ","}
                        </span>
                      ))}
                    </ul>
                  </section>
                ) : null}
              </div>
            </div>

            {related && related.length ? (
              <section
                sx={{
                  mt: [rem(80), null, rem(100)],
                  mb: [rem(60), null, rem(100)],
                }}
              >
                <h2
                  sx={{
                    fontSize: [rem(18), null, rem(24)],
                    mb: [rem(30), null, null],
                  }}
                >
                  {relatedContentHeading}
                </h2>
                <section
                  sx={{
                    display: "grid",
                    gridTemplateColumns: [
                      "repeat(1,1fr)",
                      "repeat(2,1fr)",
                      "repeat(3,1fr)",
                    ],
                    gridColumnGap: ["0.85rem", null, "3.00rem"],
                    gridRowGap: ["0.85rem", null, "3.00rem"],
                    "> div": {
                      gridColumn: "initial",
                    },
                  }}
                >
                  {related.map((hit) => (
                    <ResourceCard hit={hit} static key={hit.title} />
                  ))}
                </section>
              </section>
            ) : null}
          </Container>
        </div>
      </div>
    </Layout>
  );
};
