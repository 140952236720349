/** @jsx jsx */
import { jsx } from "theme-ui";
import { mapCraft } from "./mappings";
import { graphql } from "gatsby";

import DetailPageContainer from "@components/DetailPageContainer";

export default (props) => {
  const { entry } = mapCraft(props.data.craft);

  return (
    <DetailPageContainer
      entry={entry}
      pageType="resource"
      location={props.location}
    />
  );
};

export const query = graphql`
  query ResourceQuery($id: Craft_QueryArgument) {
    craft {
      entry(id: [$id]) {
        ...resourceVideoFields
        ...resourceStatisticFields
        ...resourceReportFields
        ...resourceInfographicFields
        ...resourceAcademicArticleFields
        ...resourceInsightFields
        ...resourceToolFields
      }
    }
  }
`;
